import React, { useEffect, useRef, useState } from 'react';
import SectionHeader from '../components/SectionHeader/sectionHeader';
import GlobalLayout from "../layouts/globalLayout";
import styles from './bookonline.module.css';

export default function BookOnline() {  
    const [height, setHeight] = useState('100%');

    useEffect(() => {
        if (window.innerWidth < 600) {
            setHeight(2700);
        } else if (window.innerWidth < 800) {
            setHeight(2400);
        } else {
            setHeight(1500);
        }
    }, []);

    return (
        <GlobalLayout bodyBackgroundColor="rgb(212, 212, 212)">
            <div className={styles.consultText}>
                <div className={styles.consultHeaderText}>
                    All new patients are required to have a consult prior to medical aesthetic services.
                </div>
                <div className={styles.consultParagraphText}>
                    COSMETIC CONSULT (30 min)………$75
                    <br/>
                    Come sit down with our Physician Assistant to discuss a treatment plan that is right for you. During the 30 minute consult, we can answer your questions regarding the aesthetic treatments that we offer here at SKIN Solutions Aesthetics. We can also discuss skincare regimens. 
                    <br/>
                    ***Consult fee will be applied to any services performed by our Physician Assistant or Esthetician. (Fees cannot be used toward skincare products). 
                </div>
            </div>
            <div className={styles.container}>
                    <iframe title="Book Online" 
                        src="https://drchrono.com/scheduling/offices/dGhpcyBpcyAxNiBjaGFyc-bPYmaUPDjgK2k5VO7AJ1g=" 
                        width="1200px"  //"650px" 
                        height={`${height}px`} 
                        frameborder="0" 
                        style={{backgroundColor: '#f0eae6', borderRadius: '35px', paddingTop: '20px'}}
                        >
                    </iframe>
            </div>
            {/* <SectionHeader title="Online Booking Coming Soon" />
            <div className={styles.title}>
                Call or email us to schedule your consultation or appointment today
            </div>
            <div className={styles.contactInfo}>
                <br />
                Email: info@skinsolutionsaesthetics.com
                <br />
                Tel: (909) 795-2050 / Fax: (951) 468-0536
            </div> */}
        </GlobalLayout>)
}
